import {Anchor, Container, Group} from '@mantine/core';
import classes from './FooterSimple.module.css';

const links = [
    {link: '#', label: '地址：北京市海淀区中关村大街18号12层1225-52'},
    {link: '#', label: '邮箱：liubinhan@tanchengsh.freeqiye.com'},
    {link: '#', label: '微信：lbh04060305'},
];

export function FooterSimple() {
    const items = links.map((link) => (
        <Anchor<'a'>
            c="dimmed"
            key={link.label}
            href={link.link}
            onClick={(event) => event.preventDefault()}
            size="sm"
        >
            {link.label}
        </Anchor>
    ));

    return (
        <div className={classes.footer}>
            <Container className={classes.inner}>
                <Group className={classes.links}>{items}</Group>
            </Container>
        </div>
    );
}
