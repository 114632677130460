'use client'

import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faTimes} from "@fortawesome/free-solid-svg-icons";
import Chatbot from "./Chatbot";

export default function GreenChatbot() {
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);

    const toggleChatbot = () => {
        setIsChatbotOpen(!isChatbotOpen);
    };
    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    padding: '10px',
                    borderRadius: '30px',
                    backgroundColor: 'rgba(55, 120, 100, 0.8)', // 半透明背景色
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    zIndex: 1000,
                }}
            >
                {/* 背景圆形 */}
                <div
                    style={{
                        position: 'absolute',
                        bottom: '32px', // 斜下偏移
                        right: '8px',
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(55, 230, 100, 1)',
                        zIndex: -1, // 置于半透明按钮下方
                    }}
                ></div>

                {/* 半透明按钮 */}
                <button
                    onClick={toggleChatbot}
                    style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(55, 180, 100, 0.68)',
                        color: 'white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backdropFilter: 'blur(2px)',
                        WebkitBackdropFilter: 'blur(2px)',
                    }}
                >
                    <FontAwesomeIcon icon={faComment}/>
                </button>
                <span
                    style={{
                        fontSize: '15px',
                        color: 'white',
                        userSelect: 'none', // 禁止选中文字
                        // 加粗字体
                        fontWeight: 'bold',
                        paddingTop: '8px',
                    }}
                >
          AI 问答
        </span>
            </div>
            {isChatbotOpen && (
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        padding: '20px',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
                        borderRadius: '8px',
                        width: '620px',
                        zIndex: 1000,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <h2 style={{margin: 0}}>碳交易 AI 顾问</h2>
                        <button
                            onClick={toggleChatbot}
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                color: 'black',
                                cursor: 'pointer',
                                fontSize: '18px',
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    </div>

                    <Chatbot/>
                </div>
            )}
        </>
    )
}