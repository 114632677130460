'use client';

import {ChartBox} from '@/components/Chart/ChartBox';
import {ZhhqChartBox} from "@/components/Chart/zhhqChart";
import {JhzlChartBox} from '@/components/Chart/JhzlChart';
import GreenChatbot from '@/components/Chatbot/GreenChatbot';
import {FeaturesCards} from '@/components/FeaturesCards/FeaturesCards';
import {FooterSimple} from '@/components/FooterSimple/FooterSimple';
import {StatsGrid} from '@/components/StatsGrid/StatsGrid';
import {Welcome} from '@/components/Welcome/Welcome';

export default function HomePage() {


    return (
        <>
            <Welcome/>
            <StatsGrid/>
            <ChartBox postfix="sshqt/quotshow.html"/>
            <ZhhqChartBox/>
            <JhzlChartBox/>
            <FeaturesCards/>
            <FooterSimple/>
            <GreenChatbot/>

        </>
    );
}
